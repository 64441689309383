<template>
    <div>
        <!-- <Breadcrumb title="Recipe"/> -->
        <div class="blog-area pt-100">
            <div class="container">
                
                <div  class="row">
                    <div style="margin-top:60px" class="col-md-6">
                        <Breadcrumb :breadCrumb="breadCrumb" title="Product" path="product.category.show" />
                    </div>
                    <div class="col-md-6">
                        <div class="sidebar-search float-right mt-5">
                            <form class="sidebar-search-form" action="#">
                                <input 
                                    style="width: 300px; display:block; margin:auto"
                                    v-model="searchKey"
                                    @input="search()"
                                    type="text" 
                                    placeholder="Type keyword">
                                
                            </form>
                            <i style="color: #a5a9a5; position: absolute; top: 67px; right: 288px;" class="fa fa-search"></i>
                        </div>
                    </div>
                </div>

                <div style="min-height:73vh" class="row mt-4">
                  <template v-if="$store.state.product.loader">
                    <div v-if="products.length<1" class="col-md-12"><h6 class="text-center">No product found...</h6></div>
                  </template>
                  <div class="col-md-12 text-center">
                    <v-progress-circular
                        v-if="!$store.state.product.loader"
                        :size="90"
                        color="amber"
                        indeterminate
                    ></v-progress-circular>
                  </div>
                  <template v-for="item in products" >
                      <div :key="item.products_id" class="col-md-3 mb-4">
                          <router-link class="readmore"
                                  :to="{name:'product.show',params:{id:item.products_id},query:{type:item.type}}">
                          <img style="object-fit: cover; display:block; margin:auto;height:200px; width: 300px" alt="" :src="item.images[0].path">
                          </router-link>
                          <p class="text-center product-name mt-3">
                              <router-link class="readmore"
                                  :to="{name:'product.show',params:{id:item.products_id},query:{type:item.type}}">
                                  {{ item.name}}
                              </router-link>
                          </p>
                      </div>
                  </template>
                </div>
            </div>
        </div>
        <notifications group="cart" position="top left" />
    </div>
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb'
    export default {
        name: "recipe",
        components:{ Breadcrumb },
        data() {
            return {
            categories: [],
            searchKey: '',
            tempProducts: [],
            state: 0,
            products: [],
            articles:[],
            img:'https://s3.ap-south-1.amazonaws.com/german-butcher/'
            }
        },
         methods: {
            // getResults( page = 1 ){
            //     this.axios.get('/articles?page=' + page).then(response => {
            //         this.articles = response.data.articles;
            //         //console.log(response.data);
            //     });
            // },
            
            // async getProductByCategoryId(id) {
            //     this.$store.state.product.loader = false;
            //     await this.axios.get(`/products/all?category=${id}`).then(response => {
            //         this.products = response.data.data;
            //         // console.log(this.products[0].name)

            //     });
            //     this.$store.state.product.loader = true;
            // },
            cartAdded(product){
                this.productId=product.product_id;
                this.$store.commit('addTCart',product);
                localStorage.setItem('cart',JSON.stringify(this.$store.getters.cart_Info));
                this.notify()
            },
            
            search(){
                //console.log(this.searchKey, '>>>>>>>')
                if(this.state == 0){
                    this.tempProducts = this.products
                    this.state = 1
                }
                var filteredItems = this.tempProducts.filter(item =>{
                    if(item.name.toLowerCase().match(this.searchKey.toLowerCase())){
                        return item;
                    }
                })
                this.products = filteredItems 
            },
            showAllproduct(){
                if(this.state == 1){
                    this.products = this.tempProducts
                    //this.state = 1
                }
            },
            filterBySub(categories_id){
                if(this.state == 0){
                    this.tempProducts = this.products
                    this.state = 1
                }
                var filteredItems = this.tempProducts.filter(item =>{
                    if(item.fk_categories_id == categories_id){
                        return item;
                    }
                })
                this.products = filteredItems
            },
            notify(){
            this.$notify({
                group: 'cart',
                type: 'error',
                title: 'Added to Cart',
                //text: 'Hello user! This is a notification!'
            });
        }
        },//methods ends here
        computed: {
            breadCrumb: function () {
                return this.$route.query
            },
            id: function(){
                return this.$route.params.id
            },
            
        },
        watch: {
            $route() {
                this.getProductByCategoryId(this.id)
            }
        },
        created() {
            this.getProductByCategoryId(this.id)
        }
        
    };
</script>

<style scoped>
    .add-cart{
    border: 1px solid #dbd7d7;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    color: #e54c2a;
    transition: .5s;
    margin-right: 20px;
  }
  .add-cart:hover{
    background-color: #e54c2a;
    color: #fff;
  }

  .product-price{
    border: 1px solid #dbd7d7;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #e54c2a;
    transition: .5s;
    margin-right: 20px;
  }
    /*@font-face {
        font-family: myThirdFont;
        src: url('/fonts/Ubuntu-M.ttf');
    }*/
  .product-name{
    font-family: 'Ubuntu', sans-serif;
    line-height: 18px;
  }
  .product-name:hover a{
      color: #e54c2a;
  }
  .col-md-3{
      overflow: hidden;
  }
</style>