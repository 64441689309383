<template>
    <div>

        <!-- <span class="itm">{{ title }}</span> > -->
        <router-link class="itm" v-if="title == 'Product'" :to="{name: 'categories', query:{type: 'gb-product'}}">
            {{ title }} >
        </router-link>
        <router-link class="itm" v-else :to="{name: 'estore'}">
            {{ title }} >
        </router-link>
        <span class="itm">
            <router-link 
                :to="{name: path, 
                params:{id:breadCrumb.catid}, 
                query:{catid: breadCrumb.catid, category: breadCrumb.category}}">{{breadCrumb.category}}
            </router-link>
         </span>
            <span v-if="breadCrumb.subcategory">></span>
        <span class="itm">
            {{breadCrumb.subcategory}}
        </span>
    </div>
</template>

<script>
    export default {
        name: 'breadcrumb',
        data (){
            return {

            }
        }, //end of data
        props: {
            breadCrumb:{required: true},
            title:{required: true},
            path: {required: true}
            
        },
        computed: {
            
        },
        methods: {

        },//end of methods
    }
</script>

<style scoped>
    .itm{
        font-size: 16px;
        font-weight: 600;
    }
    .itm:hover{
        color: #e54c2a;
        cursor: pointer;
    }
    .itm a:hover{
        color: #e54c2a;
        cursor: pointer;
    }
</style>